import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';
import { appConfig } from './app.config';
import { BrowserTracing } from '@sentry/sveltekit';

const sentryDsn = appConfig.sentryDsn;
if (typeof sentryDsn === 'string' && sentryDsn.trim().length > 0) {
	Sentry.init({
		dsn: sentryDsn,
		environment: appConfig.environment,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0
	});
}

const errorHandler = (({ error, event }) => {}) satisfies HandleClientError;
export const handleError = Sentry.handleErrorWithSentry(errorHandler);
